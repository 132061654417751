import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {AuthService} from './shared/services/auth.service';
import {RefreshTokenInterceptor} from './interceptors/refresh-token-intercepetor';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {firstValueFrom} from 'rxjs';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {RgpdService} from "./shared/services/rgpd.service";
import {GoogleTagManagerModule} from "angular-google-tag-manager";

registerLocaleData(localeFr);

function appInitializer(authService: AuthService, rgpdService: RgpdService) {
  if (typeof window !== 'undefined') {
    return () => Promise.all([
        firstValueFrom(authService.getUserByToken()),
        rgpdService.init()
      ]
    );
  }
  return () => authService.getUserByToken();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserTransferStateModule,
    RecaptchaV3Module,
    ModalModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: environment.gtmKey,
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, RgpdService],
    },
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaV3},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
