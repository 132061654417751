import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ViolationInterface} from '../../interfaces/violation.interface';
import {HttpContextToken, HttpErrorResponse} from "@angular/common/http";

export const QUIET_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  private silents: string[] = [];
  public messageSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  public message$: Observable<string | null> = this.messageSubject.asObservable();

  public violationsSubject: BehaviorSubject<ViolationInterface | null> = new BehaviorSubject<ViolationInterface | null>(null);
  public violations$: Observable<ViolationInterface | null> = this.violationsSubject.asObservable();

  async throwError(err: any) {
    if (this.silents.includes(err.url + ' | ' + err.message)) {
      return;
    }
    window.scroll(0, 0);
    let errorDetail = err.error;
    if (err.error instanceof Blob) {
      errorDetail = JSON.parse(await err.error.text());
    }
    this.messageSubject.next(errorDetail.detail || errorDetail.message || err.message);
    if (err.error?.trace?.violations) {
      const violations: ViolationInterface = {};
      errorDetail.trace.violations.forEach((value: any) => {
        if (!violations.hasOwnProperty(value.propertyPath)) {
          violations[value.propertyPath] = [];
        }
        // @ts-ignore
        violations[value.propertyPath].push(value.title);
      });
      this.violationsSubject.next(violations);
    }
  }

  public quietThis(error: HttpErrorResponse) {
    console.log('quiet');
    this.silents.push(error.url + ' | ' + error.message);
  }

  public clear() {
    this.silents = [];
    this.messageSubject.next(null);
    this.violationsSubject.next(null);
  }
}
