import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {finalize, Observable, switchMap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../shared/services/auth.service';
import {ErrorService, QUIET_ERROR} from '../shared/services/error.service';
import {LoadingService} from '../shared/services/loading.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(private authorizationService: AuthService,
              private errorService: ErrorService,
              private loadingService: LoadingService
  ) {
  }

  /**
   * @inheritDoc
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.loadingSubject.next(true);
    this.errorService.clear();
    if (req.withCredentials) {
      return next.handle(this.cloneReq(req)).pipe(
        finalize(() => this.loadingService.loadingSubject.next(false)),
        catchError((err: HttpErrorResponse) => {
          const errorResponse = err as HttpErrorResponse;
          if (errorResponse.status === 401 && errorResponse.error.message === 'Expired JWT Token') {
            return this.authorizationService.refresh().pipe(
              switchMap(() => {
                  return next.handle(this.cloneReq(req));
                }
              ));
          }
          if (!req.context.get(QUIET_ERROR)) {
            this.errorService.throwError(err);
          }
          this.loadingService.loadingSubject.next(false);
          return throwError(err);
        }));
    } else {
      return next.handle(req).pipe(
        finalize(() => this.loadingService.loadingSubject.next(false)),
        catchError((err) => {
          if (!req.context.get(QUIET_ERROR)) {
            this.errorService.throwError(err);
          }
          this.loadingService.loadingSubject.next(false);
          return throwError(err);
        })
      );
    }
  }

  private cloneReq(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone(
      {
        setHeaders: {
          Authorization: 'Bearer ' + this.authorizationService.getAuthFromLocalStorage()?.token
        }
      }
    );
  }
}
