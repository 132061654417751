// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleKey: 'AIzaSyAGCSgrUS4iUW1SzZ5PhxwKeL36ir_d2HI',
  gtmKey:'GTM-56',
  mapboxPK:'pk.eyJ1IjoiZHdtLWl0IiwiYSI6ImNsMnlpYTl3NzAweHQzYm1xa2hleGQzZHkifQ.kq2fLQYJKowNEOTa2eghrg',
  place_id: 'ChIJVZhlF1vd4EcRvePuSPYUfyU',
  reCaptchaV3: '6Ld1hiUeAAAAAJRvwonpEEJCd2awwa3IZjAU9nk5',
  apiUrl: 'https://4ptsdeplus.api.d.dwm.dev',
  appVersion: '0.0.1',
  stripePk: 'pk_test_51K4iB6G9ZSk7yM8jtOrai0tAC2dzWI1TegkcsacoE4mfFwviLeFg81FEvxvsvVsFFD5smksfiMrXSTyNyL7MtriZ00hPweRDKT'
};
