import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {UserModel} from '../models/user.model';
import {AuthModel} from '../models/auth.model';
import {catchError, map} from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import {CacheService} from './cache.service';
import {TraineeModel} from '../models/trainee.model';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public currentUserSubject: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null)
  public currentUser$: Observable<UserModel | null> = this.currentUserSubject.asObservable();
  private authLocalStorageToken = `${environment.appVersion}-token`;

  public constructor(
    private client: HttpClient,
    private cache: CacheService,
    private route: Router
  ) {
  }

  public login(username: string, password: string) {
    return this.client.post<AuthModel>(
      environment.apiUrl + '/login', {username, password})
      .pipe(
        map((result: AuthModel) => {
          localStorage.setItem(this.authLocalStorageToken, JSON.stringify(result))
          this.currentUserSubject.next(jwtDecode<UserModel>(result.token));
        }),
        switchMap(() => this.currentUser$),
        catchError(() => of(undefined))
      )
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.cache.set('canceledAction', null, true, true);
    this.currentUserSubject.next(null);
  }

  me(): Observable<TraineeModel> {
    if (this.cache.get('me') !== null) {
      return of(this.cache.get('me'));
    }

    return this.client.get<TraineeModel>(environment.apiUrl + '/me', {withCredentials: true})
      .pipe(
        map((trainee: TraineeModel) => {
          this.cache.set('me', trainee);
          return trainee;
        })
      );
  }

  refresh(): Observable<any> {
    const storage = this.getAuthFromLocalStorage();
    if (storage === undefined) {
      return of(undefined);
    }
    return this.client.post<AuthModel>(
      `${environment.apiUrl}/token/refresh`,
      {refresh_token: storage.refresh_token}
    )
      .pipe(
        catchError((err) => {
          this.logout();
          return of(undefined);
        }),
        map((result: AuthModel | undefined) => {
          if (result !== undefined) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(result))
            this.currentUserSubject.next(jwtDecode<UserModel>(result.token));
          } else {
            this.route.navigate(['/login']).then()
          }
        })
      );
  }

  createUser(user: UserModel): Observable<UserModel> {
    return this.client.post<UserModel>(environment.apiUrl + '/users', user);
  }

  forgotPassword(email: string): Observable<boolean> {
    return this.client.post<boolean>(`${environment.apiUrl}/requests/lost-password`, {
      email,
    });
  }

  getUserByToken(): Observable<UserModel | undefined> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    const user = jwtDecode<UserModel>(auth.token);
    this.currentUserSubject.next(user);
    return of(user);
  }

  public getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      return JSON.parse(lsValue);
    } catch (error) {
      return undefined;
    }
  }
}
