import {Injectable} from "@angular/core";
import {Loader} from "@googlemaps/js-api-loader";
import {environment} from "../../../environments/environment";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RgpdService {
  public googleMap: boolean = false;
  public googleLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public googleLoaded$: Observable<boolean> = this.googleLoadedSubject.asObservable();
  private axeptio: any = null;

  constructor() {

  }

  public init() {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      window._axcb ||= []
      // @ts-ignore
      window._axcb.push((axeptio: any) => {
        this.axeptio = axeptio;
        axeptio.on('cookies:complete', (choices: any) => {
          if (choices.gmaps) {
            this.loadGoogleMap();
          } else {
            this.googleLoadedSubject.next(false);
            document.getElementById('gmaps-script')?.remove();
          }
        })
      });
    }
    return Promise.resolve();
  }

  public loadGoogleMap() {
    if (!this.googleLoadedSubject.getValue()) {
      new Loader({
        apiKey: environment.googleKey,
        id: 'gmaps-script',
        version: "weekly",
        libraries: ['places']
      }).load().then(() => {
        this.googleLoadedSubject.next(true);
      });
    }
  }

  public autorize(service: string) {
    this.axeptio.requestConsent(service, () => {
    });
  }
}
